<template>
  <div>
    <div class="px-5 py-2">

      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage">
      </loading>

      <div class="row justify-content-md-center">
        <div class="col-12">
          <filter-side-bar>
            <template slot="ContentSideBar">
              <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
                <h5 class="mb-0">Filtrar por:</h5>

                <!-- filtros... -->

                <div class="py-1 text-center">
                  <div class="md-form mb-0">
                    <div class="row">
                      <div class="col-6">
                        <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                        </button>
                      </div>
                      <div class="col-6">
                        <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">
                          {{ $t('filter.reset') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </filter-side-bar>
        </div>
      </div>
    </div>

    <div class="abaixo px-5 py-2">
      <div class="row justify-content-md-center">
        <!-- Bloco com os Filtros -->
        <div class="col-12 p-2 mb-3">
<!--          <filter-container :activeFilterList="activeFilterList" />-->
        </div>

        <div class="col-12 col-sm-12 p-2">
          <div class="row">
            <div class="col-12 p-0">
              <!-- <div class="card p-0">
                <div class="card-body p-0"> -->
                  <!-- Conteúdo -->
                  <!-- Table -->
                  <custom-vue-table
                    ref="EmailListTable"
                    http-method="get"
                    :api-url="listEndpoint"
                    :fields="fieldsTable"
                    :sort-order="sortOrder"
                    :append-params="activeFilters"
                    @row-clicked="onRowClicked"
                    track-by="id">
                    <template slot="actions" slot-scope="props">
                      <div class="custom-actions">
                        <a class="btn btn-link"
                           v-bind:href="mountLink('CarouselEdit', {id: props.rowData.id_carrossel})">
                          <i class="text-primary  ti-pencil font-20"></i>
                        </a>
                        <a class="btn btn-link"
                           v-on:click="onDelete(props.rowData)">
                          <i class="text-primary  ti-trash font-20"></i>
                        </a>
                      </div>
                    </template>
                  </custom-vue-table>
                  <!-- END Table -->
                <!-- </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import FilterSideBar from '@/components/FilterSideBar.vue'
import CustomVueTable from '@/components/Table/CustomVueTable'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
// import DashboardService from '@/services/DashboardService'
// import moment from 'moment'

export default {
  name: 'HiperlinkList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.carousel') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      multiSort: true,
      listEndpoint: process.env.VUE_APP_APIENDPOINT + 'v3/carrossel',
      activeFilterList: {
      },
      filter: {},
      sortOrder: [
        { field: 'idCarrossel', direction: 'asc' }
      ]
    }
  },
  components: {
    Loading,
    FilterSideBar,
    CustomVueTable
  },
  // Apply filters to local filter
  beforeMount () {
  },
  created () {
    // let filters = {}

    // carrega rgistros
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  methods: {
    showModal () {
      // this.$modal.show(TaskLog, {}, {
      //   draggable: false,
      //   adaptive: true,
      //   scrollable: true,
      //   clickToClose: false,
      //   width: '80%',
      //   height: 'auto'
      // })
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id: object.id } }).href
    },
    handleLoadError (response) {
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    buildFilters () {
      this.filter.users = (this.filters.filUsers) ? this.filters.filUsers.map(x => (x.id)) : []
      this.filter.employees = (this.filters.filEmployees) ? this.filters.filEmployees.map(x => (x.id)) : []
      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []
      this.filter.id_status = null
    },
    applyFilter () {
      this.employeeList = []
      this.userList = []
      this.customerList = []

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuickSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuickSidebar()

      this.filters.filCliente = []
      this.filters.filEmployees = []
      this.filters.filUsers = []
      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.$refs.vuetable.refresh()
    },
    formatStatus (value) {
      if (!value && value !== 0) {
        return ' - '
      }

      let statusList = [
        {
          'label': this.$i18n.t('dashboard.inativo'),
          'color': '#F3614E'
        },
        {
          'label': this.$i18n.t('dashboard.ativo'),
          'color': '#5ec65f'
        }
      ]

      let index = 0
      if (value) {
        index = 1
      }

      let status = statusList[index]

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    formatBoolean (value) {
      if (!value) {
        return '<span style="color: #F3614E">Não</span>'
      }

      return '<span style="color: #5ec65f">Sim</span>'
    },
    formatCarouselLink (values) {
      if (!this.$util.isset(values) || values.length <= 0) {
        return '<span style="color: #F3614E">Não</span>'
      }

      let links = values.map(x => {
        return '<a href="/dashboard/admin/carousel/' + x.carrossel.id_carrossel + '">' + x.carrossel.titulo + '</a>'
      })

      return links.join(' <br/> ')
    },
    formatSample (values) {
      if (!this.$util.isset(values) || values.length <= 0) {
        return '<span style="color: #F3614E">Sem slides</span>'
      }

      return '<img src="' + values[0].hiperlink.image_url + '" width="100" height="60">'
    },
    hideQuickSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    onRowClicked (row) {
      this.$router.push({ name: 'CarouselDetail', params: { id: row.data.id_carrossel } })
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    activeFilters () {
      return this.filter
    },
    fieldsTable () {
      return [
        {
          name: 'id_carrossel',
          title: this.$i18n.t('dashboard.id'),
          sortField: 'idCarrossel',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'hiperlinks',
          title: this.$i18n.t('dashboard.image'),
          sortField: 'hiperlinks',
          formatter: (value) => this.formatSample(value)
        },
        {
          name: 'titulo',
          title: this.$i18n.t('dashboard.titulo'),
          sortField: 'titulo',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        // {
        //   name: 'dashboard',
        //   title: this.$i18n.t('dashboard.tela-inicial'),
        //   sortField: 'dashboard',
        //   dataClass: 'text-center',
        //   titleClass: 'text-center',
        //   formatter: (value) => this.formatBoolean(value)
        // },
        {
          name: 'ativo',
          title: this.$i18n.t('dashboard.status'),
          sortField: 'ativo',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatStatus(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ]
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .btn-padding {
    padding: 0.55rem 0.30rem
  }

</style>

<style>
.badge-draft {
  background-color: #ababab !important;
  color: white !important;
}

.badge-draft:hover {
  background-color: #ababab !important;
  color: white !important;
}

.badge-waiting {
  background-color: #f1ba26 !important;
  color: white !important;
}

.badge-waiting:hover {
  background-color: #f1ba26 !important;
  color: white !important;
}

.badge-confirmed {
  background-color: #5ec65f !important;
  color: white !important;
}

.badge-confirmed:hover {
  background-color: #5ec65f !important;
  color: white !important;
}

.badge-not-confirmed {
  background-color: #F3614E !important;
  color: white !important;
}

.badge-not-confirmed:hover {
  background-color: #F3614E !important;
  color: white !important;
}

.badge-cancel {
  background-color: #F3614E !important;
  color: white !important;
}

.badge-cancel:hover {
  background-color: #F3614E !important;
  color: white !important;
}

.abaixo {
  background-color: white;
}
</style>
